import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericSelectComponent } from './components/generic-select/generic-select.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { createTranslateLoader } from '../app.module';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [
    GenericSelectComponent,
    
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      defaultLanguage: 'it',
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    }),
    IonicModule
  ],
  exports: [
    GenericSelectComponent,
  ]
})
export class SharedModule { }
