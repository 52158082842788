import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  log:string;

  constructor() { }

  appendLog(msg) {
    this.log+="\n"+msg;
  }

  getLog() {
    return this.log;
  }
}
