import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  form: FormGroup;
  public languageSource = new Subject<any>();
  public languageConfirmed = this.languageSource.asObservable();
  constructor( ) { }

}
