import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { HateoasService } from './services/hateoas.service';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { PickUpPoint } from './models/pick-up-point';
import { Brand, BrandGetList } from './models/brand';
import { GetList } from './interfaces/generic-links';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from './services/utility.service';
import { Device } from '@ionic-native/device/ngx';
import { VirtualPointDeviceCfg } from './models/virtual-point-device-cfg';
import { LogService } from './services/log.service';

const PICK_UP_POINT_URL = `${environment.endpointUri}/pickUpPoints/`;
const VP_URL = `${environment.endpointUri}/virtualPointDeviceCfgs/search/findOneByUuid?projection=VPDeviceCfgBrands&uuid=`;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  english: boolean = false;
  italian: boolean = true;
  numeriUtili: string = 'home.numeriUtili';
  filter: string = '';
  langValue: any = { name: 'ITA', img: 'assets/images/italian-flag.png' };
  label: string = "home.selezionaLingua";
  values: any[] = [
    { name: 'ITA', img: 'assets/images/italian-flag.png' },
    { name: 'ENG', img: 'assets/images/english-flag.png' },
    { name: 'HEB', img: 'assets/images/israel-flag.png' }
  ];
  pupId;
  brand: string;
  myplatform: string;
  UniqueDeviceID: any;
  homeUrl: string = '/choose-brand';
  hebrew: boolean;
  language: string='ITA';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translateService: TranslateService,
    private hs: HateoasService,
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private us: UtilityService,
    public device: Device,
    public log: LogService
  ) {
    // this.loadContatti(); 
    this.brand = environment.BRAND;
    this.myplatform = platform.is("android") ? "Android" : "WEB";
    /* solo per test */
    if (environment.test) {
      this.UniqueDeviceID = environment.testUniqueDeviceID;
      this.initAndroid();
      this.changeTheme();
      this.initializeApp();
    }
    /* fine solo per test */
    /* da decommentare per andate in produzione */
    if (!environment.test && platform.is("android")) {
      platform.ready().then(() => {
        console.log("AOA OAO!!");
        log.appendLog("AO AO AO!!!");
        this.getUniqueDeviceID();
        console.log("ANVEDI");
        this.initAndroid();
        console.log("ANVEDI!!!");
      });

      console.log(platform.is("android"));

    } else {
      this.route.queryParams.subscribe(params => {

        if (params && params.pickUpPoint) {
          this.getPickUpPoint(params.pickUpPoint);
        } else {
          this.getPickUpPoint(environment.pickUpPointId);
        }
      });
      this.changeTheme();
      this.initializeApp();
    }
  }


  initAndroid() {
    let vp_url = VP_URL + this.UniqueDeviceID;
    console.log(vp_url);
    this.log.appendLog(vp_url);
    this.hs.get<VirtualPointDeviceCfg>(VP_URL + this.UniqueDeviceID).subscribe(result => {
      console.log("ANFAME", JSON.stringify(result));
      this.log.appendLog(JSON.stringify(result));
      let url = result._links.pickUpPoint.href.replace("{?projection}", "?projection=PUPData");
      console.debug(url);
      this.hs.get<PickUpPoint>(url).subscribe(pup => {
        console.debug(pup);
        this.loadPup(pup);
      }
        , error => { this.log.appendLog(JSON.stringify(error)); }
      );
      /**inizio commento martina */
      //this.brand = result.brand;
      //this.changeTheme(); 
      //this.initializeApp();
      /**fine commento martina  */

      /**nuovo code martina */
      if (result.brands && result.brands.length == 1) {
        this.homeUrl = `/home`
        this.brand = result.brands[0].code;
        let url = result._links.pickUpPoint.href.replace("{?projection}", "?projection=PUPData");
        console.debug(url);
        this.brand = result.brand;
        this.changeTheme();
        this.initializeApp();
      }
      else {
        //abbiamo più brand, redirect pagina di scelta
        this.homeUrl = '/choose-brand';
        console.log(result.brands);
        // let navigationExtras: NavigationExtras = { state: { brands: result.brands}};
        this.router.navigate(['/choose-brand']);
      }
      /**fine nuovo code martina */
    });
  }

  changeTheme() {
    // this.brand = brand;
    // let element = document.getElementById("logo") as HTMLImageElement;
    // element.src = './assets/images/avis-logo-bianco.svg';
    // let logoFooter =  document.getElementById("logoFooter") as HTMLImageElement;

    if (this.brand == 'MAGGIORE') {
      this.brand = 'MAGGIORE';
      document.documentElement.style.setProperty('--ion-color-primary', '#02285f');
      // logoFooter.src = './assets/images/abg-logo.svg';

      // element.src = './assets/images/logo-maggiore-white.svg';
    } else if (this.brand == 'AVIS') {
      document.documentElement.style.setProperty('--ion-color-primary', '#d4002a');
      // logoFooter.src = './assets/images/avi-logo-pallini.svg';

    } else if (this.brand == 'BUDGET') {
      document.documentElement.style.setProperty('--ion-color-primary', '#0046a4');
      // logoFooter.src = './assets/images/budget/logo-white.svg';
    }

  }

  getUniqueDeviceID() {
    this.UniqueDeviceID = this.device.uuid;
    console.log(this.device.platform, this.device.model, this.device.uuid, this.device.version, this.device.serial);
    this.log.appendLog(this.device.uuid);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.translateService.setDefaultLang('it');
      this.translateService.use('it');
      if (localStorage.getItem("language") != null) {
        let lang = localStorage.getItem("language");
        let index = this.values.findIndex(x => x.name == lang);
        if (index != -1) {
          this.langValue = this.values[index];
          this.changeLanguage(this.langValue.name);
        }
      }
    });
  }

  // loadContatti() {
  //   this.http.get("assets/contatti/" + this.langValue.name + ".json").subscribe(
  //     contatti => {
  //       localStorage.setItem("header", contatti["numeriUtili"].header);
  //       localStorage.setItem("titlePrenPrivati", contatti["numeriUtili"].titlePrenPrivati);
  //       localStorage.setItem("subtitlePrenPrivati", contatti["numeriUtili"].subtitlePrenPrivati);
  //       localStorage.setItem("telefonoPrenPrivati", contatti["numeriUtili"].telefonoPrenPrivati);
  //       localStorage.setItem("titlePrenAziende", contatti["numeriUtili"].titlePrenAziende);
  //       localStorage.setItem("subtitlePrenAziende", contatti["numeriUtili"].subtitlePrenAziende);
  //       localStorage.setItem("telefonoPrenAziende", contatti["numeriUtili"].telefonoPrenAziende);
  //       localStorage.setItem("titleClienteAziende", contatti["numeriUtili"].titleClienteAziende);
  //       localStorage.setItem("subtitleClienteAziende", contatti["numeriUtili"].subtitleClienteAziende);
  //       localStorage.setItem("telefonoClienteAziende", contatti["numeriUtili"].telefonoClienteAziende);
  //       localStorage.setItem("titleRichiestaVoucherEmail", contatti["numeriUtili"].titleRichiestaVoucherEmail);
  //       localStorage.setItem("subRichiestaVoucherEmail", contatti["numeriUtili"].subRichiestaVoucherEmail);
  //       localStorage.setItem("emailVoucher", contatti["numeriUtili"].emailVoucher);
  //       localStorage.setItem("titleRichiestaVoucherTel", contatti["numeriUtili"].titleRichiestaVoucherTel);
  //       localStorage.setItem("subRichiestaVoucherTel", contatti["numeriUtili"].subRichiestaVoucherTel);
  //       localStorage.setItem("telVoucher", contatti["numeriUtili"].telVoucher);
  //     }
  //   )
  // }

  getPickUpPoint(pupID: number) {
    if (pupID) {

      let url = `${PICK_UP_POINT_URL}`;
      this.hs.get<PickUpPoint>(`${url}${pupID}?projection=PUPData`).subscribe(
        pup => {
          this.loadPup(pup);
        }
      );
    }
  }

  loadPup(pup: PickUpPoint) {
    localStorage.setItem("pickUpPoint", JSON.stringify(pup));
    localStorage.setItem("serviceRoomId", JSON.parse(localStorage.getItem("pickUpPoint")).serviceRoom.id);
    //prendere la company in funzione del brand
    let companyURL = JSON.parse(localStorage.getItem("pickUpPoint"))._links.company.href;
    this.hs.get(companyURL).subscribe(
      company => {
        localStorage.setItem("company", JSON.stringify(company));
        let brandURL = JSON.parse(localStorage.getItem("company"))._links.brands.href;
        this.hs.get<GetList<BrandGetList>>(brandURL).subscribe(
          brand => {
            localStorage.setItem("brand", JSON.stringify(brand._embedded.brands[0]));
          },
          error => {
            this.pupId = error;
            console.log(error);
          });
      },
      error => {
        this.pupId = error;
        console.log(error);
      }
    );
  }

  // getPermission(){
  //   this.androidPermissions.checkPermission(
  //     this.androidPermissions.PERMISSION.READ_PHONE_STATE
  //   ).then(res => {
  //     if(res.hasPermission){

  //     }else{
  //       this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_PHONE_STATE).then(res => {
  //         alert("Persmission Granted Please Restart App!");
  //       }).catch(error => {
  //         alert("Error! "+error);
  //       });
  //     }
  //   }).catch(error => {
  //     alert("Error! "+error);
  //   });
  // }


  /* per la select */
  // changeLanguage(language: any) {
  //   if (language.name == 'ENG') {
  //     this.translateService.use('en');
  //   } else if (language.name == 'ITA') {
  //     this.translateService.use('it');
  //   }
  //   localStorage.setItem("language", language.name);
  // }

  changeLanguage(language) {
    this.english = false;
    this.italian = false;
    let pupID = JSON.parse(localStorage.getItem("pickUpPoint")).id;
    // this.http.get("assets/contatti/" + pupID + "_" + language + ".json").subscribe(
    //   contatti => {
    //     localStorage.setItem("header", contatti["numeriUtili"].header);
    //     localStorage.setItem("titlePrenPrivati", contatti["numeriUtili"].titlePrenPrivati);
    //     localStorage.setItem("subtitlePrenPrivati", contatti["numeriUtili"].subtitlePrenPrivati);
    //     localStorage.setItem("telefonoPrenPrivati", contatti["numeriUtili"].telefonoPrenPrivati);
    //     localStorage.setItem("titlePrenAziende", contatti["numeriUtili"].titlePrenAziende);
    //     localStorage.setItem("subtitlePrenAziende", contatti["numeriUtili"].subtitlePrenAziende);
    //     localStorage.setItem("telefonoPrenAziende", contatti["numeriUtili"].telefonoPrenAziende);
    //     localStorage.setItem("titleClienteAziende", contatti["numeriUtili"].titleClienteAziende);
    //     localStorage.setItem("subtitleClienteAziende", contatti["numeriUtili"].subtitleClienteAziende);
    //     localStorage.setItem("telefonoClienteAziende", contatti["numeriUtili"].telefonoClienteAziende);
    //     localStorage.setItem("titleRichiestaVoucherEmail", contatti["numeriUtili"].titleRichiestaVoucherEmail);
    //     localStorage.setItem("subRichiestaVoucherEmail", contatti["numeriUtili"].subRichiestaVoucherEmail);
    //     localStorage.setItem("emailVoucher", contatti["numeriUtili"].emailVoucher);
    //     localStorage.setItem("titleRichiestaVoucherTel", contatti["numeriUtili"].titleRichiestaVoucherTel);
    //     localStorage.setItem("subRichiestaVoucherTel", contatti["numeriUtili"].subRichiestaVoucherTel);
    //     localStorage.setItem("telVoucher", contatti["numeriUtili"].telVoucher);
    //     this.us.languageSource.next(true);
    //   }
    // )
    // this.loadContatti();
    this.language=language;
    switch (language) {
      case 'ENG':
        this.translateService.use('en');
        this.english = true;
        break;
      case 'ITA':
        this.translateService.use('it');
        this.italian = true;
        break;
      case 'HEB':
        this.translateService.use('heb');
        this.hebrew = true;
        break;


    }

  }

  readLocalStorageBrand(): string {
    if (localStorage.getItem("brand"))
      return (JSON.parse(localStorage.getItem("brand")) as Brand).code;
    return null;
  }

}
