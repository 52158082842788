// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  endpointUri: 'http://negozio-avis.dstech.info/api',
  // endpointUri: 'http://172.30.2.182/api',
  // endpointUri: 'http://localhost:8081/api',
  // endpointUri: 'http://192.168.2.217:8081/api',
  pickUpPointId:48,
  production: false,
  // pickUpPointId:48,
  // endpointUri: 'https://avis-smartcheckout.dst-api.info/api',
  // endpointUri: 'http://172.30.2.182/api',
  // BRAND:'AVIS'
  // BRAND:'BUDGET'
  //testUniqueDeviceID:'352f2d6242be49b3',
  // testUniqueDeviceID:'3815f6c3f1661c63',
  testUniqueDeviceID:'9d7915cd8b6e3693',
  // testUniqueDeviceID:'76a7b113efb23129',
  BRAND:'MAGGIORE',
  test: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
