import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, finalize, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  constructor(private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = localStorage.getItem("x-auth-token");
    let authReq = req;
    if (token != null) {
      authReq = req.clone({ headers: req.headers.set("x-auth-token", token) });
    }
    return next.handle(authReq)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            return event;
          }
        }),
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
          } 
          return throwError(err);
        }),
        finalize(() => {

        })
      );
  }  
}
