import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-generic-select',
  templateUrl: './generic-select.component.html',
  styleUrls: ['./generic-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GenericSelectComponent implements OnInit {

  @Input() label: string = 'home.selezionaLingua';
  @Input() image: boolean = false;
  @Input() values: any[] = [];
  @Input() value: any;
  @Input() selectedPointId: number;
  @Output() onSelecetedValue: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public router: Router
  ) {}

  ngOnInit() {
    if (this.image) {
      let self = this;
      setTimeout(function(){ 
        const selected = document.querySelector('ion-select').shadowRoot.querySelector(".select-text");
        selected.innerHTML = selected.innerHTML.concat(`<img style="width: 25px; height:16px; margin-left: 5px;" src="${self.value.img}" />`);
      }, 300);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.values !== undefined && changes.values.currentValue !== changes.values.previousValue) {
      this.values = changes.values.currentValue;
    }
  }

  loadFlags() {
    if (this.image) {
      let self = this;
      setTimeout(function(){ 
        const radios = document.getElementsByClassName('alert-radio-label');
        for (let i:number = 0; i < radios.length; i++) {
            let element = radios[i];
            element.innerHTML = element.innerHTML.concat(`<img style="width: 25px; height:16px;" src="${self.values[i].img}" />`);
          }
      }, 300);
    }
  }

  onChange(event) {
    if (this.image) {
      let self = this;
      setTimeout(function(){ 
        const selected = document.querySelector('ion-select').shadowRoot.querySelector(".select-text");
        selected.innerHTML = `${self.value.name}`;
        selected.innerHTML = selected.innerHTML.concat(`<img style="width: 25px; height:16px; margin-left: 5px;" src="${self.value.img}" />`);
      }, 300);
    }
    this.value = event.detail.value;
    this.onSelecetedValue.emit(this.value);
  }

  compareWithFn = (o1, o2) => {
    let pup1 = new Object(o1);
    let pup2 = new Object(o2);
    return o1 && o2 ? pup1 === pup2 : o1 === o2;
  };

  compareWith = this.compareWithFn;

}
