import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./features/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'choose-brand',
    pathMatch: 'full'
  },
  {
    path: 'smart-checkout',
    loadChildren: () => import('./features/smart-checkout/smart-checkout.module').then(m => m.SmartCheckoutPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./features/map/map.module').then(m => m.MapPageModule)
  },
  {
    path: 'calendario',
    loadChildren: () => import('./features/calendario/calendario.module').then(m => m.CalendarioPageModule)
  },
  {
    path: 'ho-gia-prenotato',
    loadChildren: () => import('./features/ho-gia-prenotato/ho-gia-prenotato.module').then(m => m.HoGiaPrenotatoPageModule)
  },
  {
    path: 'success-page',
    loadChildren: () => import('./features/success-page/success-page.module').then( m => m.SuccessPagePageModule)
  },
  {
    path: 'contatti',
    loadChildren: () => import('./features/contatti/contatti.module').then( m => m.ContattiPageModule)
  },
  {
    path: 'choose-brand',
    loadChildren: () => import('./features/choose-brand/choose-brand.module').then( m => m.ChooseBrandPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
